<template>
  <div class="notification-wrapper">
    <div class="message-wrapper">
      <h1 class="notification">{{  message  }}</h1>
      <h2 class="promo-title upper">{{ promo.title }}</h2>
    </div>
    <AppLinkButton name="catalogue" label="Перейти в каталог"/>
  </div>
</template>

<script>
import { getPromoByCode, getClientById, activatePromo } from "@/API/promo-api";
import AppLinkButton from '@/components/ui/buttons/AppLinkButton';
import { mapActions, mapState } from 'vuex';
import promoErrors from "@/errors/promo.error";

export default {
  name: "ActivatePromo",
  components: {
    AppLinkButton
  },
  data() {
    return {
      message: '',
    }
  },
  methods: mapActions(['setClient', 'setPromo']),
  computed: mapState(['promo']),
  async created() {
    const { clientId, code } = this.$route.params;
    const promo = await getPromoByCode(code);
    const checkedClientId = await getClientById(clientId);

    if (!promo) {
      this.message = promoErrors.NOT_EXIST;
      return;
    }

    const activePromo = await activatePromo(promo['is_active'], checkedClientId, promo.id);
    if (!promo['is_active']) {
      this.message = promoErrors.IS_EXPIRED;
    } else {
      this.message = 'Ваш код успешно активирован!';
      this.setClient({ id: checkedClientId });
      this.setPromo({...promo, activeId: activePromo.id });
    }
  }
}
</script>

<style>
/* Global Style main.css */
</style>